import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingDestination: false,
  errorDestination: false,
  destinations: [],
  loadingCreatingDestination: false,
  errorCreatingDestination: false,
  loadingDeletingDestination: false,
  errorDeletingDestination: false,
};

const sortDestinations = (destinations) => {
  return destinations.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

const destinationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DESTINATION_FETCH_LOADING:
      return {
        ...state,
        loadingDestination: true,
        errorDestination: false,
      };
    case actionTypes.DESTINATION_FETCH_SUCCESS:
      return {
        ...state,
        loadingDestination: false,
        destinations: sortDestinations(action.payload),
        errorDestination: false,
      };
    case actionTypes.DESTINATION_FETCH_FAILURE:
      return {
        ...state,
        loadingDestination: false,
        errorDestination: true,
      };
    case actionTypes.DESTINATION_CREATE_LOADING:
      return {
        ...state,
        loadingCreatingDestination: true,
        errorCreatingDestination: false,
      };
    case actionTypes.DESTINATION_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreatingDestination: false,
        errorCreatingDestination: false,
      };
    case actionTypes.DESTINATION_CREATE_FAILURE:
      return {
        ...state,
        loadingCreatingDestination: false,
        errorCreatingDestination: true,
      };
    case actionTypes.DESTINATION_DELETE_LOADING:
      return {
        ...state,
        loadingDeletingDestination: true,
        errorDeletingDestination: false,
      };
    case actionTypes.DESTINATION_DELETE_SUCCESS:
      return {
        ...state,
        loadingDeletingDestination: false,
        errorDeletingDestination: false,
      };
    case actionTypes.DESTINATION_DELETE_FAILURE:
      return {
        ...state,
        loadingDeletingDestination: false,
        errorDeletingDestination: true,
      };
    default:
      return state;
  }
};

export default destinationReducer;
