export const ADMIN_MENU = [
  { label: "Inicio", link: "inicio" },
  { label: "Usuarios", link: "users" },
  { label: "Conductores", link: "conductores" },
  { label: "Transfers", link: "transfers" },
  { label: "Destinos", link: "destinos" },
  { label: "Viajes", link: "viajes" },
  {label: "Reportes", link: "reports"}
];

export const TRANSFER_MENU = [
  { label: "Inicio", link: "inicio" },
  { label: "Destinos", link: "destinos" },
  { label: "Conductores", link: "conductores" },
  { label: "Transfers", link: "transfers" },
  { label: "Viajes", link: "viajes" },
];

export const TOURIST_MENU = [
  { label: "Inicio", link: "inicio" },
];

export const ADMIN_ROLE = 'admin';
export const TRANSFER_ROLE = 'transfer';
export const TOURIST_ROLE = 'tourist';

export const CALENDAR_VIEWS = {
  semana: "timeGridWeek",
  mes: "dayGridMonth",
  dia: "list",
};

export const DATE_FORMAT = {
  es: "DD-MM-YYYY  HH:mm:ss",
  headerDate: "MMM YYYY",
};

export const DATE_FORMAT_WITHOUT_TIME = {
  es: "DD-MM-YYYY",
  headerDate: "MMM YYYY",
};

export const INITIAL_VISIBLE_COLUMNS = ["firstname", "actions"];

export const INITIAL_VISIBLE_COLUMNS_DRIVER = ["firstname", "email", "lastname", "actions"];
export const STATUS_COLOR_MAP = {
  open: "success",
  closed: "warning",
};

export const COLUMNS_STATUS_TRAVELS = {
  open: 'abierto',
  closed: 'cerrado'
}

export const STATUS_COLOR_RESERVATION = {
  true: "success",
  false: "danger",
};


export const COLUMNS_USERS = [
  {name: "Nombre", uid: "firstname", sortable: true},
  {name: "Email", uid: "email"},
  {name: "Email Confirmado", uid: "has_updated_initial_password"},
  {name: "Estado", uid: "is_deleted", sortable: true},
  {name: "", uid: "actions"},
];

export const STATUS_OPTIONS_USERS = [
  {name: "Activo", uid: "active"},
  {name: "Borrado", uid: "deleted"},
];

export const STATUS_OPTIONS_DRIVERS = [
  {name: "Activo", uid: "active"},
  {name: "Borrado", uid: "deleted"},
];

export const COLUMNS_DRIVERS = [
  {name: "Nombre", uid: "firstname", sortable: true},
  {name: "Email", uid: "email"},
  {name: "Apellido", uid: "lastname", sortable: true},
  {name: "", uid: "actions"},
];

export const STATUS_OPTIONS_TRANSFERS = [
  {name: "Operativo", uid: "operative"},
  {name: "No operativo", uid: "inoperative"},
  {name: "En reparación", uid: "inRepair"},
  {name: "Necesita revisión", uid: "needRevision"}
];

export const STATUS_COLOR_TRANSFERS = {
  operative: "success",
  inoperative: "danger",
  observation: 'primary',
  inRepair: 'secondary',
  needRevision: "warning",
};

export const COLUMNS_TRANSFERS = [
  {name: "Patente", uid: "license_plate", sortable: true},
  {name: "Marca", uid: "brand", sortable: true},
  {name: "Modelo", uid: "model", sortable: true},
  {name: "Año", uid: "year", sortable: true},
  {name: "Capacidad", uid: "capacity", sortable: true},
  {name: "Estado", uid: "status", sortable: true},
  {name: "Apto para personas con discapacidad", uid: 'suitable_for_disabled_people'},
  {name: "Observación", uid: "observations", sortable: true},
  {name: "", uid: "actions"},
];

export const INITIAL_VISIBLE_COLUMNS_TRANSFERS = ["license_plate", "brand", "actions"];

export const INITIAL_VISIBLE_COLUMNS_DESTINATION = ["name", "actions"];
export const INITIAL_VISIBLE_COLUMNS_TRAVELS = ["destination", "start_date", "end_date", "status", "actions"];

export const COLUMNS_TRAVELS = [
  {name: "ID", uid: "id"},
  {name: "Destino", uid: "destination", sortable: true},
  {name: "Conductor", uid: "driver", sortable: true},
  {name: "Fecha de salida", uid: "start_date", sortable: true},
  {name: "Fecha de regreso", uid: "end_date", sortable: true},
  {name: "Vehículo", uid: "transfer", sortable: true},
  {name: "Estado", uid: "status", sortable: true},
  {name: "", uid: "actions"},
];

export const COLUMNS_PASSENGERS = [
  {
    key: "namePassenger",
    label: "Nombre",
  },
  {
    key: "surnamePassenger",
    label: "Apellido",
  },
  {
    key: "dniPassenger",
    label: "Documento",
  },
  {
    key: "button-delete",
    label: ""
  }
];

export const CLIENT_TYPE = [{label: 'Empresa', value: 'company'}, {label: 'Individual', value: 'individual'}];

export const INITIAL_VISIBLE_COLUMNS_RESERVATIONS = ["destination", "start_date", "end_date", "status", "actions"];

export const COLUMNS_RESERVATION = [
  {name: "Destino", uid: "destinationName", sortable: true},
  {name: "Fecha de salida", uid: "start_date", sortable: true},
  {name: "Fecha de regreso", uid: "end_date", sortable: true},
  {name: "Dueño de reserva", uid: "client_name", sortable: true},
  {name: "Estado", uid: "confirmed", sortable: true},
  {name: "", uid: "actions"},
];

export const COLUMNS_RESERVATIONS = [
  {
    key: "namePassenger",
    label: "Nombre",
  },
  {
    key: "surnamePassenger",
    label: "Apellido",
  },
  {
    key: "dniPassenger",
    label: "Documento",
  },
  {
    key: "button-delete",
    label: ""
  }
];

export const ROLE_CONFIRM_RESERVATION = ['admin']

export const INITIAL_VISIBLE_COLUMNS_PAYMENTS = ["installment", "payment_type"];

export const COLUMNS_PAYMENTS = [
  {name: "Entregado", uid: "installment", sortable: true},
  {name: "Fecha de pago", uid:"created_at", sortable: true},
  {name: "Forma de pago", uid: "payment_type", sortable: true},
];

export const COLUMNS_TYPE_PAYMENTS = [{key: 'cash',name: 'Efectivo'},{key: 'debit', name: 'debito'},{key:'bankTransfer', name:'Transferencia bancaria'}, {key:'credit', name: 'Tarjeta de crédito'}]

export const AVATAR_DESTINATION = "https://thumbs.dreamstime.com/z/l%C3%ADnea-icono-del-paisaje-de-la-monta%C3%B1a-las-monta%C3%B1as-el-sol-y-lago-vector-ejemplo-aislado-en-blanco-dise%C3%B1o-estilo-esquema-124871312.jpg?w=768";

export const COLUMNS_DESTINATIONS = [
  {name: "Nombre", uid: "name", sortable: true},
  {name: "Precio para particulares", uid: "particular_price", sortable: false},
  {name: "Precio empresarial", uid: "enterprise_price", sortable: false},
  {name: "Observación", uid: "observations", sortable: true},
  {name: "Distancia", uid: "distance"},
  {name: "", uid: "actions"},
];

export const CALENDAR_EVENT_COLORS = {
  backgroundRed: "#fb9ebe",
  backgroundGreen: "#c1e9cd",
  backgroundYellow: "#fcdab8",
  fontRed: "#634050",
  fontGreen: "#485d59",
  fontYellow: "#41382e",
};

export const REPORT_DRIVER_ITEMS = [
  {id:'distance_traveled' , label:'Distancia Recorrida'},
  {id:'travel_count' , label:'Viajes Realizados'},
  {id:'destinations' , label:'Destinos'},
  {id:'passengers_count' , label:'Conteo de pasajeros'},
];

export const REPORT_VEHICLE_ITEMS = [
  {id:'distance_traveled', label:'Distancia Recorrida'},
  {id:'travel_count', label:'Viajes Realizados'},
  {id:'destinations' , label:'Destinos'},
  {id:'passengers_count' , label:'Conteo de pasajeros'},
];

export const REPORT_DESTINATION_ITEMS = [
  {id:'distance', label:'Distancia'},
  {id:'vehicles', label:'Vehiculos'},
  {id:'passengers_count' , label:'Conteo de pasajeros'},
];

export const REPORT_TRIP_ITEMS = [
  {id:'passengers_list' , label:'Lista de pasajeros'},
  {id:'passengers_count' , label:'Conteo de pasajeros'},
  {id:'distance', label:'Distancia'},
  {id:'reservations' , label:'Reservas'},
  {id:'trip_date' , label:'Fecha de viaje'},
  {id:'driver_name' , label:'Conductor'},
  {id:'license_plate' , label:'Patente'},
  {id:'profit' , label:'Ganancia'},
];

export const REPORT_RESERVATION_ITEMS = [
  {id:'trip_date' , label:'Fecha de viaje'},
  {id:'client_name' , label:'Nombre de cliente'},
  {id:'client_type' , label:'Tipo de cliente'},
  {id:'passenger_list' , label:'Lista de pasajeros'},
  {id:'price' , label:'Precio'},
];