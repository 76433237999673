import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import toast from "react-hot-toast";


const getReservationsLoading = () => ({
  type: actionTypes.RESERVATIONS_FETCH_LOADING,
});

const getReservationsSuccess = (data) => ({
  type: actionTypes.RESERVATIONS_FETCH_SUCCESS,
  payload: data,
});

const getReservationsFailure = () => ({
  type: actionTypes.RESERVATIONS_FETCH_FAILURE,
});

const getReservations = () => async (dispatch) => {
  dispatch(getReservationsLoading());
  let { data, error } = await supabase.from("reservations").select(`id,
    created_at,
    created_by,
    seats_reserved,
    client_name,
    price,
    passenger_list,
    confirmed,
    trip,
    tripRow: trip(*),
    payed,
    left_to_pay,
    client_type`);
  if (!error) {
    dispatch(getReservationsSuccess(data));
  } else {
    dispatch(getReservationsFailure());
  }
};

const createReservationLoading = () => ({
  type: actionTypes.RESERVATION_CREATE_LOADING,
});

const createReservationSuccess = () => ({
  type: actionTypes.RESERVATION_CREATE_SUCCESS,
});

const createReservationFailure = () => ({
  type: actionTypes.RESERVATION_CREATE_FAILURE,
});

const addPassengers = (formData, successCallback) => async (dispatch) =>{
  dispatch(createReservationLoading());
  const { error } = await supabase
    .from("trip")
    .insert([
      {
        destination_id: formData.destination_id,
        vehicle_id: formData.vehicle_id,
        driver_id: formData.driver_id,
        created_by: formData.created_by,
        start_date: formData.start_date.toLocaleString('en-US'),
        end_date: formData.end_date.toLocaleString('en-US')
      },
    ])
    .select();
  if (!error) {
    dispatch(createReservationSuccess());
    successCallback();
    dispatch(getReservations());
  } else {
    dispatch(createReservationFailure());
  }
} 

const createReservation = (formData, successCallback) => async (dispatch) => {
  dispatch(createReservationLoading());
  const { error } = await supabase
    .from("reservations")
    .insert([
      {
        created_by: formData.created_by,
        seats_reserved: formData.seats_reserved,
        client_name: formData.client_name,
        passenger_list: formData.passenger_list,
        confirmed: formData.confirmed,
        trip: formData.trip,
        price: formData.price,
        client_type:  formData.client_type,
        payed: false,
        left_to_pay: formData.price

      },
    ])
    .select();
  if (!error) {
    dispatch(createReservationSuccess());
    successCallback();
    dispatch(getReservations());
  } else {
    dispatch(createReservationFailure());
  }
};

const updateReservation = (formData, successCallback) => async (dispatch) => {
  dispatch(createReservationLoading());
  const { error } = await supabase
    .from("trip")
    .update({
      destination_id: formData.destination_id,
      vehicle_id: formData.vehicle_id,
      driver_id: formData.driver_id,
      created_by: formData.created_by,
      start_date: formData.start_date.toLocaleString('en-US'),
      end_date: formData.end_date.toLocaleString('en-US')
    })
    .eq("id", parseInt(formData.id))
    .select();
  if (!error) {
    dispatch(createReservationSuccess());
    successCallback();
    dispatch(getReservations());
  } else {
    dispatch(createReservationFailure());
  }
}

const updateStatusReservation = (id, successCallback) => async (dispatch) => {
  dispatch(deleteReservationLoading());
  const { error } = await supabase
    .from("reservations")
    .update({
      confirmed: true
    })
    .eq("id", id)
    .select();
  if (!error) {
    successCallback();
    dispatch(deleteReservationSuccess());
    dispatch(getReservations());
  } else {
    dispatch(deleteReservationFailure());
  }
}

const deleteReservationLoading = () => ({
  type: actionTypes.RESERVATION_DELETE_LOADING,
});

const deleteReservationSuccess = () => ({
  type: actionTypes.RESERVATION_DELETE_SUCCESS,
});

const deleteReservationFailure = () => ({
  type: actionTypes.RESERVATION_DELETE_FAILURE,
});


const deleteReservation = (id, successCallback) => async (dispatch) => {
  dispatch(deleteReservationLoading());
  const { error } = await supabase.from("reservations").delete().eq("id", id);
  if (!error) {
    successCallback();
    dispatch(deleteReservationSuccess());
    dispatch(getReservations());
  } else {
    dispatch(deleteReservationFailure());
  }
}

export { getReservations, createReservation, updateReservation, deleteReservation, updateStatusReservation }
