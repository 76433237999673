import React from "react";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
} from "@nextui-org/react";

import {STATUS_COLOR_RESERVATION} from "../../constants/index"
import { VerticalDotsIcon } from "../commons/icons/VerticalDotsIcon";
import useReservations from "../../hooks/reservations/useReservations";
import moment from 'moment-timezone';
import 'moment/locale/es';
import { useSessions } from '../../hooks/useSessions';
import { isAdmin } from "../../utilities/rolePermissionHelpper";



  export default function CellReservations({reservation, columnKey, editOrCreateReservation, deleteReservation, paymentReservation, trip, confirmReservation}) {
    const { reservations } = useReservations();
    const cellValue = reservation[columnKey];
    const reservationSelected = reservations.find(reservationItem => reservation.id == reservationItem.id);
    const { isLogged, onLogOut, userInformation, publicUserData } = useSessions();
    const userRole = publicUserData && publicUserData.userRole;
    const admin = isAdmin(userRole)
    switch (columnKey) {
      case "confirmed":
        return (

          <Chip
            className="capitalize"
            color={STATUS_COLOR_RESERVATION[reservation.confirmed.toString()]}
            size="sm"
            variant="flat"
          >
            {reservation.confirmed ? 'Confirmado': 'Pendiente'}
          </Chip>
        );
      case "end_date":
          moment.locale('es');
          return moment(reservation.tripRow.end_date).format('LLL');
      case "start_date":
        moment.locale('es');
        return moment(reservation.tripRow.start_date).format('LLL');
      case "actions":
        return (

          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onPress={() => editOrCreateReservation("showreservations", reservationSelected)}>
                  Ver
                </DropdownItem>
                <DropdownItem onPress={() => paymentReservation("show", reservationSelected)}>
                  Ver pagos
                </DropdownItem>
                <DropdownItem onPress={() => paymentReservation('create',reservationSelected)}>
                  Agregar pago
                </DropdownItem>
                {admin &&
                  <DropdownItem onPress={() => confirmReservation(reservation.id)}>
                    Confirmar Reserva
                  </DropdownItem>
                }
                <DropdownItem onPress={() => deleteReservation(reservation.id)}>Eliminar</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return  cellValue;
    }
  }
  